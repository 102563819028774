import { FunctionComponent, useCallback, useState } from 'react';
import './modalSortStyle.scss';
interface MobileFiltersSelectProps {
  onFilterChange?: (filters: any) => void;
  profile?: any;
  sortOptions?: { value: string; label: string }[];
  isVisible?: any;
  filters?: any;
  onClose?: any;
}
const ModalSort: FunctionComponent<MobileFiltersSelectProps> = ({
  isVisible = true,
  onFilterChange,
  onClose,
  sortOptions,
  filters,
}) => {
  const initialFilters = {
    category: 'All',
    minPrice: undefined,
    maxPrice: undefined,
    sortOrder: 'Sort',
    enoughBalance: false,
  };
  const [isClosing, setIsClosing] = useState(false);
  //const [filters, setFilters] = useState(initialFilters);

  const handleSortOrderChange = (value: string) => {
    const newFilters = { ...filters, sortOrder: value };
    //setFilters(newFilters);
    onFilterChange(value);
    onClose();
  };

  // Если передана функция закрытия, добавляем обработчик клика
  const handleClick = () => {
    if (onClose) {
      setIsClosing(true);
      setTimeout(() => {
        onClose();
      }, 300); // Длительность анимации
    }
  };

  return (
    <>
      <div
        className={`modal_select_overlay ${isClosing ? 'closing' : ''}`}
        onClick={handleClick}
      />{' '}
      <div className={`modal_select ${isClosing ? 'closing' : ''}`}>
        <h3>Select category</h3>
        <ul>
          {sortOptions.map((option) => (
            <li
              key={option.value}
              onClick={() => handleSortOrderChange(option.value)}
            >
              {option.label}
            </li>
          ))}
          {/* <li>Icy race</li>
          <li>Counter-Strike 2</li>
          <li>Classic cases</li>
          <li>Special cases</li>
          <li>Pirate gang</li> */}
        </ul>
      </div>
    </>
  );
};

export default ModalSort;
