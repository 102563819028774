export default function Settings(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        d="M0 20c0-.75.625-1.313 1.313-1.313H8v2.625H1.312A1.32 1.32 0 0 1 0 20ZM0 4c0-.75.625-1.313 1.313-1.313h12v2.626h-12A1.32 1.32 0 0 1 0 4Zm13.313 18.688A1.32 1.32 0 0 1 12 24a1.32 1.32 0 0 1-1.313-1.313v-5.375A1.32 1.32 0 0 1 12 16c.75 0 1.313.625 1.313 1.313v1.375h9.374A1.32 1.32 0 0 1 24 20a1.32 1.32 0 0 1-1.313 1.313h-9.375v1.375Zm-8-13.375C5.313 8.624 5.938 8 6.688 8 7.375 8 8 8.625 8 9.313v5.374C8 15.376 7.375 16 6.687 16c-.75 0-1.375-.625-1.375-1.313v-1.374h-4A1.32 1.32 0 0 1 0 12c0-.75.625-1.313 1.313-1.313h4V9.313ZM24 12a1.32 1.32 0 0 1-1.313 1.313h-12v-2.626h12A1.32 1.32 0 0 1 24 12Zm-6.688-4C16.625 8 16 7.375 16 6.687V1.313C16 .625 16.625 0 17.313 0c.75 0 1.375.625 1.375 1.313v1.375h4A1.32 1.32 0 0 1 24 4a1.32 1.32 0 0 1-1.313 1.313h-4v1.375c0 .687-.625 1.312-1.375 1.312Z"
      />
    </svg>
  );
}
