import Settings from './settings';
import './homeFiltersMob.scss';
import { FunctionComponent, useState } from 'react';
import ModalWrapper from '~frontend/components/modals/mobile-filter/modal-wrapper/modalWrapper';
import MobileFilter from '~frontend/components/modals/mobile-filter/mobileFilter';
import { useConfig, useProfile } from '~frontend/components/hooks';

interface HomeFiltersProps {
  onFilterChange: (filters: any) => void;
  profile: any;
  categoryOptions: { value: string; label: string }[];
}

export const HomeFiltersMob: FunctionComponent<HomeFiltersProps> = ({
  onFilterChange,
  profile,
  categoryOptions,
}) => {
  const initialFilters = {
    category: 'All',
    minPrice: undefined,
    maxPrice: undefined,
    sortOrder: 'Sort',
    enoughBalance: false,
  };

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const getProfile = useProfile();

  const handleOpenFilterModal = () => setIsFilterModalOpen(true);
  const handleCloseFilterModal = () => setIsFilterModalOpen(false);

  const [filters, setFilters] = useState(initialFilters);

  const handleCategoryChange = (value: string) => {
    const newFilters = { ...filters, category: value };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleMinPriceChange = (value: number | null) => {
    const newFilters = { ...filters, minPrice: value ?? undefined };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleMaxPriceChange = (value: number | null) => {
    const newFilters = { ...filters, maxPrice: value ?? undefined };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleSortOrderChange = (value: string) => {
    const newFilters = { ...filters, sortOrder: value };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleEnoughBalanceChange = (e: any) => {
    const newFilters = { ...filters, enoughBalance: e.target.checked };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const isAnyFilterActive = () => {
    const { category, minPrice, maxPrice, sortOrder, enoughBalance } = filters;
    return (
      category !== 'All' ||
      minPrice !== undefined ||
      maxPrice !== undefined ||
      sortOrder !== 'Sort' ||
      enoughBalance
    );
  };

  const handleResetFilters = () => {
    setFilters(initialFilters);
    onFilterChange(initialFilters);
  };
  return (
    <>
      <div className="home_filters_mob">
        <button
          className="home_filters_mob_settings"
          onClick={handleOpenFilterModal}
        >
          <Settings />
        </button>
        <div className="home_filters_mob_categories">
          {categoryOptions.map((option) => (
            <button
              key={option.value}
              onClick={() => handleCategoryChange(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
      <ModalWrapper
        isOpen={isFilterModalOpen}
        onClose={handleCloseFilterModal}
        title="Filter"
        className="filter_modal"
      >
        <MobileFilter
          onFilterChange={onFilterChange}
          profile={getProfile}
          onClose={handleCloseFilterModal}
          categoryOptions={categoryOptions}
        />
      </ModalWrapper>
    </>
  );
};
