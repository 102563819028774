// import Search from '@/app/components/icons/search';
// import ArrowDown from '@/app/components/icons/arrow-down';
import { InputNumber, Select } from 'antd';
// import Dollar from '@/app/components/icons/dollar';
// import Sort from '@/app/components/icons/sort';
import { Checkbox } from 'antd';
import { FunctionComponent, useCallback, useState } from 'react';
// import ModalWrapper from '@/app/components/modal-wrapper';
// import ModalSelect from '@/app/components/modal-select';
import './mobileFilterStyle.scss';
import ArrowDown from '~frontend/pages/layouts/icons/arrow-down';
import Currency from '~frontend/components/Currency';
import Sort from '~frontend/components/home/home-filters/sort';
import { Button } from '~shared/frontend/ui/button/button';
import InputWithIcon from '~shared/frontend/ui/input-with-icon/inputWithIcon';
import { Search } from '~frontend/components/header';
import ModalWrapper from './modal-wrapper/modalWrapper';
import ModalSelect from './modal-select/modalSelect';
import ResetFilter from '~frontend/components/home/home-filters/reset-filter';
import { filter } from 'lodash';
import ModalSort from './modal-sort/modalSort';

interface MobileFiltersProps {
  onFilterChange: (filters: any) => void;
  profile: any;
  categoryOptions: { value: string; label: string }[];
  onClose?: any;
}

const MobileFilter: FunctionComponent<MobileFiltersProps> = ({
  onFilterChange,
  profile,
  onClose,
  categoryOptions,
}) => {
  const initialFilters = {
    category: 'All',
    minPrice: undefined,
    maxPrice: undefined,
    sortOrder: 'Sort',
    enoughBalance: false,
  };
  const [filters, setFilters] = useState(initialFilters);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenSelectModal = () => setIsSelectModalOpen(true);
  const handleCloseSelectModal = () => setIsSelectModalOpen(false);

  const handleOpenSortModal = () => setIsSortModalOpen(true);
  const handleCloseSortModal = () => setIsSortModalOpen(false);

  const handleCategoryChange = useCallback(
    (value: string) => {
      const newFilters = { ...filters, category: value };
      setFilters(newFilters);
      onFilterChange(newFilters);
    },
    [filters, onFilterChange],
  );

  const handleMinPriceChange = useCallback(
    (value: number | null) => {
      const newFilters = { ...filters, minPrice: value ?? undefined };
      setFilters(newFilters);
      onFilterChange(newFilters);
    },
    [filters, onFilterChange],
  );

  const handleMaxPriceChange = useCallback(
    (value: number | null) => {
      const newFilters = { ...filters, maxPrice: value ?? undefined };
      setFilters(newFilters);
      onFilterChange(newFilters);
    },
    [filters, onFilterChange],
  );

  const handleSortOrderChange = useCallback(
    (value: string) => {
      const newFilters = { ...filters, sortOrder: value };
      setFilters(newFilters);
      onFilterChange(newFilters);
    },
    [filters, onFilterChange],
  );

  const handleEnoughBalanceChange = useCallback(
    (e: any) => {
      const newFilters = { ...filters, enoughBalance: e.target.checked };
      setFilters(newFilters);
      onFilterChange(newFilters);
    },
    [filters, onFilterChange],
  );

  const isAnyFilterActive = () => {
    const { category, minPrice, maxPrice, sortOrder, enoughBalance } = filters;
    return (
      category !== 'All' ||
      minPrice !== undefined ||
      maxPrice !== undefined ||
      sortOrder !== 'Sort' ||
      enoughBalance
    );
  };

  const handleApplyFilters = () => {
    onClose();
  };

  const handleResetFilters = useCallback(() => {
    setFilters(initialFilters);
    onFilterChange(initialFilters);
  }, [filters, onFilterChange]);

  return (
    <>
      <div className="mobile_filter">
        <div className="mobile_filter_content">
          <div className="mobile_filter_item">
            <label>Search by cases and skins</label>

            <InputWithIcon
              icon={<Search />}
              iconPosition="left"
              placeholder="Search"
              value=""
              disabled={true}
              className="mobile_filter_item_input"
              // onChange={() => {}}
            />
          </div>

          <div className="mobile_filter_item">
            <label>Select category</label>
            <div
              className="mobile_filter_item_select"
              onClick={handleOpenSelectModal}
            >
              {
                categoryOptions.find(
                  (option) => option.value === filters.category,
                )?.label
              }
              <ArrowDown />
            </div>
          </div>

          <div className="mobile_filter_item">
            <label>Price</label>
            <div className={`home_filters_item home_filters_item_price`}>
              <InputNumber
                value={filters.minPrice}
                onChange={handleMinPriceChange}
                prefix={<Currency />}
                controls={false}
              />
              <InputNumber
                value={filters.maxPrice}
                onChange={handleMaxPriceChange}
                prefix={<Currency />}
                controls={false}
              />
            </div>
          </div>

          <div className="mobile_filter_item">
            <label>Sort by</label>
            <div
              className="mobile_filter_item_select"
              onClick={handleOpenSortModal}
            >
              <Sort color="#ffffff" />
              <span>{filters.sortOrder}</span>
              <ArrowDown />
            </div>
          </div>

          <Checkbox
            checked={filters.enoughBalance}
            onChange={handleEnoughBalanceChange}
          >
            Enough balance
          </Checkbox>

          <Button
            text="Apply"
            onClick={handleApplyFilters}
            className={`btn1_yellow mobile_filter_btn`}
          />

          <Button
            onClick={handleResetFilters}
            text="Reset filters"
            className={`mobile_filter_btn`}
          />
        </div>
      </div>

      <ModalWrapper
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Filter"
      ></ModalWrapper>
      {/* Показываем ModalSelect, когда нужно */}
      {isSelectModalOpen && (
        <ModalSelect
          categoryOptions={categoryOptions}
          filters={filters}
          onFilterChange={handleCategoryChange}
          onClose={handleCloseSelectModal}
        />
      )}

      {isSortModalOpen && (
        <ModalSort
          sortOptions={[
            { value: 'Sort', label: 'Sort' },
            { value: 'Lowest price first', label: 'Lowest price first' },
            { value: 'Hight price first', label: 'Hight price first' },
          ]}
          onFilterChange={handleSortOrderChange}
          filters={filters}
          onClose={handleCloseSortModal}
        />
      )}
    </>
  );
};

export default MobileFilter;
