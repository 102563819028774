import {
  useCallback,
  memo,
  MouseEventHandler,
  SyntheticEvent,
  FunctionComponent,
  useRef,
  useEffect,
} from 'react';
import { useConfig } from '../hooks/useConfig';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';

import _ from 'lodash';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useClickAway, useToggle } from 'react-use';

const langImage = {
  ru: require(`../../scss/images/ru.svg`),
  en: require(`../../scss/images/en.svg`),
  de: require(`../../scss/images/de.svg`),
  tr: require(`../../scss/images/tr.svg`),
  pl: require(`../../scss/images/pl.svg`),
  pt: require(`../../scss/images/pt.svg`),
  th: require(`../../scss/images/th.svg`),
  hi: require(`../../scss/images/hi.svg`),
};

interface LangProps {
  key: string;
  lang: string;
  setLanguage: MouseEventHandler<HTMLDivElement>;
  language: string;
}

const Lang: FunctionComponent<LangProps> = memo(
  ({ lang, setLanguage, language }) => (
    <div
      data-lang={lang}
      className={`header__controls-lang-dropdown-item ${
        lang === language && 'active'
      }`}
      onClick={setLanguage}
    >
      <LazyLoadImage className="lang-icon" src={langImage[lang]} alt="" />
      <span>{lang}</span>
    </div>
  ),
);

Lang.displayName = 'Lang';

export const Languages: FunctionComponent = () => {
  const ref = useRef(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { reFetchObservableQueries } = useApolloClient();

  const [, setCookie] = useCookies();
  const { i18n } = useTranslation();
  const getConfig = useConfig();
  const languages = i18n.languages;

  const [active, setActive] = useToggle(false);

  useClickAway(ref, () => {
    setActive(false);
  });

  useEffect(() => {
    setActive(false);
  }, [i18n.language, setActive]);

  const setLanguage = useCallback(
    (
      event: SyntheticEvent<HTMLDivElement>,
      { lang } = event.currentTarget.dataset,
    ) => {
      const language = i18n.language;
      const expires = new Date();
      expires.setDate(expires.getDate() + 14);
      setCookie('lang', lang, { expires: expires, path: '/' });

      if (getConfig?.seo.language !== 'ru') {
        return (window.location.href = _.replace(
          pathname,
          `/${language}`,
          `/${lang}`,
        ));
      }

      i18n.changeLanguage(lang);

      reFetchObservableQueries();

      if (_.replace(pathname, language, lang) !== pathname) {
        navigate(_.replace(pathname, `/${language}`, `/${lang}`));
      }
    },
    [
      i18n,
      setCookie,
      getConfig?.seo.language,
      reFetchObservableQueries,
      pathname,
      navigate,
    ],
  );

  return (
    <div
      className={`header__controls-lang ${active ? 'active' : ''}`}
      onClick={setActive}
      ref={ref}
    >
      <div className="header__controls-lang-current">
        <LazyLoadImage
          className="lang-icon"
          src={langImage[i18n.language]}
          alt="current_lang"
        />
        {/* <span>{i18n.language}</span> */}
        {/* <span className="icon material-icons" data-icon="&#xe5c5;"></span> */}
      </div>
      <div className="header__controls-lang-dropdown">
        {languages.map((lang) => (
          <Lang
            key={lang}
            setLanguage={setLanguage}
            language={i18n.language}
            lang={lang}
          />
        ))}
      </div>
    </div>
  );
};
